import { DCInput, PostVariantPayload, ReleaseStatus, Variant } from '@adsk/offsite-dc-sdk';
import {
  PrintMessageDialog,
  ProductCustomizationForm,
  ProductDetails,
  productCustomizationTestIds,
  transformToVariantPayloadRfaOnly,
  usePrintMessageDialog,
} from '@mid-react-common/addins';
import {
  analytics,
  ANALYTICS_ACTIONS,
  MIDEditFormAppMoniker,
  ModalContext,
  NotificationContext,
  PreviewWrapper,
} from '@mid-react-common/common';
import {
  ConfigurePreviewContainer,
  ConfigureProductCustomizationContainer,
  ConfigureScrollableContainer,
  NameAndRelease,
  ProductCustomizationFooter,
  StaticContentFormNotification,
  VariantFormState,
  VariantThumbnail,
  useCacheVariant,
  useProductCustomizationFooter,
  useProductCustomizationFormInRevit,
  useRepresentation,
} from '@mid-react-common/revit-components';
import Divider from '@mui/material/Divider';
import { createVariant } from 'mid-addin-lib';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import text from '../../global/mid-edit-form.text.json';
import {
  ConfigurePreviewContainerLoader,
  ConfigureScreenEditInPlaceWrapper,
  ProductCustomizationFooterWrapper,
} from './ConfigureScreen.styles';
import { useGenerateVariant } from './useGenerateVariant';
import { getProductConfigurableStatus, setDefaultInputValuesIfNotApplicable } from 'mid-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CircularProgress } from '@mui/material';
import { useDataStore, dataStoreActions } from 'context/dataStore';
import { useShallow } from 'zustand/react/shallow';

// Segment Event handler
const handleSegmentVariantsGenerateEvent = (sessionId: string, postedVariant: Variant) => {
  analytics.track(ANALYTICS_ACTIONS.MIDEF.generateVariant, {
    session_id: sessionId,
    sourceMoniker: MIDEditFormAppMoniker,
    variantId: postedVariant.variantId,
    variantName: postedVariant.name,
    releaseNumber: postedVariant.release,
    projectId: postedVariant.tenancyId,
    productId: postedVariant.contentId,
    numberOfInputs: postedVariant.inputs.length,
  });
};

export const ConfigureScreen: React.FC = () => {
  const { enableStaticContent } = useFlags();
  const { isMessageDialogOpen, dialogMessage, showMessageDialog, closeMessageDialog } = usePrintMessageDialog();

  const { dontShowAgain, setModalState } = useContext(ModalContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);

  const {
    sessionId,
    rfaInstance,
    currentProductRelease,
    currentVariantOfInstance,
    selectedCachedVariant,
    configurableProductProperties,
    variantFormState,
    selectedRepresentation,
    productReleasesListLength,
    isFormInitializing,
  } = useDataStore(
    useShallow((state) => ({
      rfaInstance: state.rfaInstance,
      currentProductRelease: state.currentProductRelease,
      currentVariantOfInstance: state.currentVariantOfInstance,
      selectedCachedVariant: state.selectedCachedVariant,
      configurableProductProperties: state.configurableProductProperties,
      variantFormState: state.variantFormState,
      selectedRepresentation: state.selectedRepresentation,
      productReleasesListLength: state.productReleasesListLength,
      isFormInitializing: state.isFormInitializing,
    })),
  );

  const {
    setSelectedCachedVariant,
    setVariantFormState,
    updateConfigurableProductInputs,
    setSelectedRepresentation,
    setReFetchCachedVariants,
    resetConfigurableProductProperties,
    addToPostedVariantIdsList,
    setIsFormInitializing,
  } = dataStoreActions;

  const incomingAccBridgeData = useMemo(
    () => ({
      sourceFolderUrn: rfaInstance?.sourceFolderUrn,
      sourceProjectId: rfaInstance?.sourceProjectId,
      targetProjectId: rfaInstance?.targetProjectId,
    }),
    [rfaInstance],
  );

  useEffect(() => {
    // We need to always set isFormInitializing to true when the component is mounted,
    // so that the rules are executed when you switch tabs or select a cached variant.
    setIsFormInitializing(true);
  }, [setIsFormInitializing]);

  const [savedVariant, setSavedVariant] = useState<Variant | undefined>();

  // data and functions required for ProductCustomizationForm
  const {
    productCustomizationFormError,
    isFormDataValid,
    setIsFormDataValid,
    inputsError,
    handleInputUpdate,
    formRules,
    tabValue,
    handleTabChange,
  } = useProductCustomizationFormInRevit({
    currentProductRelease,
    configurableProductProperties,
    updateConfigurableProductInputs,
    setVariantFormState,
    logAndShowNotification,
    showMessageDialog,
    isFormInitializing,
    setIsFormInitializing,
  });

  const postVariant = useCallback(
    async (targetProjectId: string, productId: string, release: number): Promise<Variant> => {
      // We set the default values for the inputs that are not applicable for the current inputs.
      // This makes sure that this variant gets cached no matter what the input value is for the inputs
      // that are not applicable.
      const inputPayload: DCInput[] = currentProductRelease?.inputs
        ? setDefaultInputValuesIfNotApplicable(configurableProductProperties.inputs, currentProductRelease.inputs)
        : configurableProductProperties.inputs;

      const postVariantPayload: PostVariantPayload = {
        ...transformToVariantPayloadRfaOnly(
          { ...configurableProductProperties, inputs: inputPayload },
          selectedRepresentation,
        ),
        release,
      };

      const savedVariant: Variant = await createVariant({
        projectId: targetProjectId,
        productId,
        variantPayload: postVariantPayload,
        incomingAccBridgeData,
      });

      setSavedVariant(savedVariant);
      setSelectedCachedVariant(savedVariant);

      return savedVariant;
    },
    [
      currentProductRelease?.inputs,
      configurableProductProperties,
      selectedRepresentation,
      incomingAccBridgeData,
      setSelectedCachedVariant,
    ],
  );

  // TODO: Refactor UI to take advantage of cached variant.
  // see: https://jira.autodesk.com/browse/TRADES-6404
  const { handleGenerateNewVariantClick } = useGenerateVariant({
    sessionId,
    currentProductRelease,
    rfaInstance,
    postVariant,
    setVariantFormState,
    setReFetchCachedVariants,
    dontShowAgain,
    setModalState,
    showNotification,
    handleSegmentVariantsGenerateEvent,
    addToPostedVariantIdsList,
  });

  const { cachedVariantThumbnail, setCachedVariantThumbnail } = useCacheVariant({
    incomingAccBridgeData,
    currentProductRelease,
    selectedCachedVariant,
    setSelectedCachedVariant,
    selectedRepresentation,
  });

  // data and functions required for ProductCustomizationFooter
  const { handleResetClick } = useProductCustomizationFooter({
    setVariantFormState,
    resetConfigurableProductProperties,
    setSelectedCachedVariant,
    setIsFormInitializing,
    setCachedVariantThumbnail,
  });

  const handleRepresentationStateChange = (): void => {
    if (setVariantFormState) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);
    }
  };

  const {
    productReleaseRFAOutput,
    rfaRepresentationDropdownItems,
    selectedRepresentationDropdownItem,
    handleRfaRepresentationSelection,
  } = useRepresentation({
    configurableProductProperties,
    selectedRepresentation,
    setSelectedRepresentation,
    handleRepresentationStateChange,
  });

  const { productCustomizationFormWrapper } = productCustomizationTestIds;

  const isProductConfigurable = getProductConfigurableStatus(currentProductRelease?.isConfigurable);

  return (
    <>
      <ConfigureScreenEditInPlaceWrapper>
        <ConfigureProductCustomizationContainer data-testid={productCustomizationFormWrapper}>
          {enableStaticContent && currentProductRelease && !isProductConfigurable && (
            <StaticContentFormNotification
              message={
                currentProductRelease.inputs.length > 0
                  ? text.parameterValuesAreReadOnlyForStaticReleases
                  : text.staticReleaseDoesNotIncludeParameters
              }
            />
          )}
          <ProductCustomizationForm
            inactive={
              isFormInitializing ||
              variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
              currentProductRelease?.status === ReleaseStatus.OBSOLETE
            }
            error={productCustomizationFormError}
            inputs={configurableProductProperties.inputs}
            inputsError={inputsError}
            handleInputUpdate={handleInputUpdate}
            setIsFormDataValid={setIsFormDataValid}
            formLayoutRules={formRules}
            isFormLoading={isFormInitializing}
            isProductConfigurable={isProductConfigurable}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
          />
        </ConfigureProductCustomizationContainer>
        <Divider orientation="vertical" />

        {isFormInitializing ? (
          <ConfigurePreviewContainerLoader>
            <CircularProgress />
          </ConfigurePreviewContainerLoader>
        ) : (
          <ConfigurePreviewContainer>
            {currentProductRelease && (
              <NameAndRelease
                currentProductRelease={currentProductRelease}
                productReleasesListLength={productReleasesListLength}
              />
            )}
            <ConfigureScrollableContainer>
              <PreviewWrapper>
                <VariantThumbnail
                  tenancyId={currentProductRelease?.tenancyId}
                  isLoading={variantFormState === VariantFormState.GENERATING_NEW_VARIANT}
                  thumbnail={savedVariant?.thumbnail || cachedVariantThumbnail || currentVariantOfInstance?.thumbnail}
                  alternateMessage={
                    variantFormState === VariantFormState.GENERATING_NEW_VARIANT ? text.generatingNewVariant : undefined
                  }
                  incomingAccBridgeData={incomingAccBridgeData}
                />
              </PreviewWrapper>
              <ProductDetails
                title={text.MIDEditForm.productDetails}
                productCategory={productReleaseRFAOutput?.options?.category || ''}
                productFamily={productReleaseRFAOutput?.options?.family || ''}
                representations={productReleaseRFAOutput?.options?.modelStates || []}
                releaseNotes={currentProductRelease?.notes}
                isConfigurable={isProductConfigurable}
                authoringAppEngine={currentProductRelease?.context.engine.location}
              />
            </ConfigureScrollableContainer>
          </ConfigurePreviewContainer>
        )}
      </ConfigureScreenEditInPlaceWrapper>
      <Divider />
      <ProductCustomizationFooterWrapper>
        <ProductCustomizationFooter
          handleResetClick={handleResetClick}
          handleGenerateNewVariantClick={handleGenerateNewVariantClick}
          handleRfaRepresentationSelection={handleRfaRepresentationSelection}
          variantFormState={variantFormState}
          isFormDataValid={isFormDataValid}
          selectedRepresentationDropdownItem={selectedRepresentationDropdownItem}
          representationDropdownItems={rfaRepresentationDropdownItems}
          currentProductReleaseStatus={currentProductRelease?.status}
          isProductConfigurable={isProductConfigurable}
          disableVariantGeneration={!isProductConfigurable}
        />
      </ProductCustomizationFooterWrapper>
      <PrintMessageDialog isMessageDialogOpen={isMessageDialogOpen} closeMessageDialog={closeMessageDialog} isAddin>
        {dialogMessage}
      </PrintMessageDialog>
    </>
  );
};
