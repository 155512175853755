export const VariantFormState = {
  DEFAULT_VARIANT: 'DEFAULT_VARIANT',
  EDITING_NEW_VARIANT: 'EDITING_NEW_VARIANT',
  GENERATING_NEW_VARIANT: 'GENERATING_NEW_VARIANT',
  VARIANT_GENERATED: 'VARIANT_GENERATED',
  EXISTING_VARIANT_SELECTED: 'EXISTING_VARIANT_SELECTED',
  INSERTING_VARIANT: 'INSERTING_VARIANT',
  REPLACING_VARIANT: 'REPLACING_VARIANT',
  VARIANT_RFA_OUTPUT_PENDING: 'VARIANT_RFA_OUTPUT_PENDING',
  VARIANT_REPLACED_OR_INSERTED: 'VARIANT_REPLACED_OR_INSERTED',
} as const;

export const VariantFormStatesWhenVariantExists = [
  VariantFormState.DEFAULT_VARIANT,
  VariantFormState.EXISTING_VARIANT_SELECTED,
  VariantFormState.VARIANT_GENERATED,
];

export type VariantFormStates = (typeof VariantFormState)[keyof typeof VariantFormState];

export const configureAndSelectTabs = {
  TAB_CONFIGURE: 0,
  TAB_SELECT: 1,
} as const;
export type ConfigureAndSelectTabs = (typeof configureAndSelectTabs)[keyof typeof configureAndSelectTabs];
