import { create } from 'zustand';
import { DCInput, DCOutput, ProductRelease, ReleaseStatus, Variant } from '@adsk/offsite-dc-sdk';
import { ConfigurableProductProperties } from '@mid-react-common/common';
import { VariantFormState, VariantFormStates } from '@mid-react-common/revit-components';
import { mergeProductReleaseInputsWithVariantInputValues, mergeVariantAndProductReleaseInputs } from '../utils';
import { ProductsUtils } from 'mid-addin-lib';
import { getUUID, isDCRfaOutput, isVariantRfaOutput } from 'mid-utils';
import { MIDSelectedRFAInstance } from 'global/selectedRFAInstance.utils';
import { DCProductUIExtension, DCRules } from 'mid-types';

export interface DataStore {
  rfaInstance: MIDSelectedRFAInstance | undefined;
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  currentVariantOfInstance: Variant | undefined;
  currentProductReleaseOfInstance: DCProductUIExtension<ProductRelease> | undefined;
  selectedCachedVariant: Variant | null;
  configurableProductProperties: ConfigurableProductProperties;
  mergedProductReleaseVariantInputsMap: Map<string, DCInput>;
  variantFormState: VariantFormStates;
  cachedVariantsList: Variant[];
  selectedRepresentation: string | undefined;
  reFetchCachedVariants: boolean;
  productReleasesListLength: number | undefined;
  nonObsoleteProductReleasesList: DCProductUIExtension<ProductRelease>[] | undefined;
  selectedVariantThumbnailVisible: boolean;
  postedVariantIdsList: string[];
  isFormInitializing: boolean;
  sessionId: string;
}

export interface DataStoreActions {
  setRfaInstance: (rfaInstance: MIDSelectedRFAInstance | undefined) => void;
  setCurrentProductRelease: (currentProductRelease: DCProductUIExtension<ProductRelease> | undefined) => void;
  setCurrentVariantOfInstance: (currentVariantOfInstance: Variant | undefined) => void;
  setCurrentProductReleaseOfInstance: (
    currentProductReleaseOfInstance: DCProductUIExtension<ProductRelease> | undefined,
  ) => void;
  setSelectedCachedVariant: (selectedCachedVariant: Variant | null) => void;
  updateConfigurableProductInput: (inputToUpdate: DCInput) => void;
  updateConfigurableProductInputs: (updatedInputs: DCInput[], productReleaseOutputs?: DCOutput[]) => void;
  setVariantFormState: (variantFormState: VariantFormStates) => void;
  setCachedVariantsList: (cachedVariantsList: Variant[]) => void;
  setSelectedRepresentation: (selectedRepresentation: string | undefined) => void;
  setReFetchCachedVariants: (reFetchCachedVariants: boolean) => void;
  resetConfigurableProductProperties: () => void;
  initConfigurableProductProperties: () => void;
  setProductReleasesList: (productReleasesList: DCProductUIExtension<ProductRelease>[] | undefined) => void;
  handleProductReleaseChange: (productRelease: DCProductUIExtension<ProductRelease>) => Promise<void>;
  setSelectedVariantThumbnailVisible: (isSelectedVariantThumbnailVisible: boolean) => void;
  addToPostedVariantIdsList: (postedVariantId: string) => void;
  setIsFormInitializing: (isFormInitializing: boolean) => void;
}

export const useDataStore = create<DataStore>(() => ({
  rfaInstance: undefined,
  currentProductRelease: undefined,
  currentVariantOfInstance: undefined,
  currentProductReleaseOfInstance: undefined,
  selectedCachedVariant: null,
  configurableProductProperties: {
    inputs: [],
    outputs: [],
  } as ConfigurableProductProperties,
  mergedProductReleaseVariantInputsMap: new Map<string, DCInput>(),
  variantFormState: VariantFormState.DEFAULT_VARIANT,
  cachedVariantsList: [],
  selectedRepresentation: undefined,
  reFetchCachedVariants: true,
  productReleasesListLength: 0,
  nonObsoleteProductReleasesList: undefined,
  selectedVariantThumbnailVisible: true,
  postedVariantIdsList: [],
  isFormInitializing: false,
  sessionId: getUUID(),
}));

export const dataStoreActions: DataStoreActions = {
  setRfaInstance: (rfaInstance: MIDSelectedRFAInstance | undefined) => useDataStore.setState({ rfaInstance }),
  setCurrentProductRelease: (currentProductRelease: DCProductUIExtension<ProductRelease> | undefined) =>
    useDataStore.setState({ currentProductRelease }),
  setCurrentVariantOfInstance: (currentVariantOfInstance: Variant | undefined) =>
    useDataStore.setState({ currentVariantOfInstance }),
  setCurrentProductReleaseOfInstance: (currentProductReleaseOfInstance: DCProductUIExtension<ProductRelease> | undefined) =>
    useDataStore.setState({ currentProductReleaseOfInstance }),
  setSelectedCachedVariant: (selectedCachedVariant: Variant | null) => useDataStore.setState({ selectedCachedVariant }),
  updateConfigurableProductInput: (inputToUpdate: DCInput) => {
    const { mergedProductReleaseVariantInputsMap } = useDataStore.getState();
    const clonedInputsMap = new Map<string, DCInput>(mergedProductReleaseVariantInputsMap);
    clonedInputsMap.set(inputToUpdate.name, inputToUpdate);

    useDataStore.setState((state) => ({
      mergedProductReleaseVariantInputsMap: clonedInputsMap,
      configurableProductProperties: {
        ...state.configurableProductProperties,
        inputs: [...clonedInputsMap.values()],
      },
    }));
  },
  updateConfigurableProductInputs: (inputsToUpdate: DCInput[]) => {
    const inputMaps = inputsToUpdate.reduce((acc: Map<string, DCInput>, input: DCInput) => {
      acc.set(input.name, input);
      return acc;
    }, new Map<string, DCInput>());

    useDataStore.setState((state) => ({
      mergedProductReleaseVariantInputsMap: inputMaps,
      configurableProductProperties: {
        ...state.configurableProductProperties,
        inputs: [...inputMaps.values()],
      },
    }));
  },
  resetConfigurableProductProperties: () => {
    const { currentProductReleaseOfInstance, currentVariantOfInstance } = useDataStore.getState();

    if (currentProductReleaseOfInstance && currentVariantOfInstance) {
      const rfaOutput = currentProductReleaseOfInstance?.outputs.find(isDCRfaOutput);
      const mergedProductReleaseVariantInputs = mergeVariantAndProductReleaseInputs(
        currentProductReleaseOfInstance.inputs,
        currentVariantOfInstance.inputs,
      );

      useDataStore.setState({
        configurableProductProperties: {
          inputs: mergedProductReleaseVariantInputs,
          outputs: currentProductReleaseOfInstance.outputs,
        },
        mergedProductReleaseVariantInputsMap: new Map<string, DCInput>(
          mergedProductReleaseVariantInputs.map((input) => [input.name, input]),
        ),
        selectedRepresentation: rfaOutput?.options?.modelStates?.at(0),
        currentProductRelease: currentProductReleaseOfInstance,
      });
    }
  },
  initConfigurableProductProperties() {
    dataStoreActions.resetConfigurableProductProperties();
  },
  setVariantFormState: (variantFormState: VariantFormStates) => useDataStore.setState({ variantFormState }),
  setCachedVariantsList: (cachedVariantsList: Variant[]) => useDataStore.setState({ cachedVariantsList }),
  setSelectedRepresentation: (selectedRepresentation: string | undefined) =>
    useDataStore.setState({ selectedRepresentation }),
  setReFetchCachedVariants: (reFetchCachedVariants: boolean) => useDataStore.setState({ reFetchCachedVariants }),
  setProductReleasesList: (productReleasesList: DCProductUIExtension<ProductRelease>[] | undefined) =>
    useDataStore.setState({
      productReleasesListLength: productReleasesList?.length,
      nonObsoleteProductReleasesList: productReleasesList?.filter((release) => release.status !== ReleaseStatus.OBSOLETE),
    }),
  setSelectedVariantThumbnailVisible: (isSelectedVariantThumbnailVisible: boolean) =>
    useDataStore.setState({ selectedVariantThumbnailVisible: isSelectedVariantThumbnailVisible }),
  addToPostedVariantIdsList: (postedVariantId: string): void => {
    const { postedVariantIdsList } = useDataStore.getState();
    useDataStore.setState({ postedVariantIdsList: [...postedVariantIdsList, postedVariantId] });
  },
  handleProductReleaseChange: async (productRelease: DCProductUIExtension<ProductRelease>) => {
    const { rfaInstance, mergedProductReleaseVariantInputsMap, currentVariantOfInstance, selectedCachedVariant } =
      useDataStore.getState();
    const { sourceProjectId, targetProjectId } = rfaInstance || {};

    const productReleaseRules: DCRules | undefined =
      (await ProductsUtils.checkAndDownloadProductRulesFromKey({
        tenancyId: productRelease.tenancyId,
        rulesKey: productRelease.rulesKey,
        incomingAccBridgeData: {
          sourceProjectId,
          targetProjectId,
        },
      })) || productRelease.rules;

    let inputsToUpdateConfigurableProductInputs: DCInput[] = productRelease.inputs;
    if (productRelease.isConfigurable !== false) {
      // Only If Release is Configurable, we should merge the inputs from the Product Release
      // with the current Variant inputs already selected
      const currentVariantInputValues = [...mergedProductReleaseVariantInputsMap.values()];
      inputsToUpdateConfigurableProductInputs = mergeProductReleaseInputsWithVariantInputValues(
        productRelease.inputs,
        currentVariantInputValues || currentVariantOfInstance?.inputs || [],
      );
    }
    const inputsToUpdateMaps = inputsToUpdateConfigurableProductInputs.reduce(
      (acc: Map<string, DCInput>, input: DCInput) => {
        acc.set(input.name, input);
        return acc;
      },
      new Map<string, DCInput>(),
    );

    const productReleasesRFAOutputModelStates = productRelease.outputs.find(isDCRfaOutput)?.options.modelStates;
    const selectedCachedVariantRFAOutput = selectedCachedVariant?.outputs.filter(isVariantRfaOutput);
    // Changing releases, it can happens that selected representation from cached variant is not available in new release
    const availableModelStateRFAInProductRelease = selectedCachedVariantRFAOutput?.find((output) =>
      productReleasesRFAOutputModelStates?.includes(output.modelState),
    );
    let representationToSelect = availableModelStateRFAInProductRelease?.modelState;
    if (!availableModelStateRFAInProductRelease) {
      // If the selected representation is not available in the new release, select the first one
      representationToSelect = productReleasesRFAOutputModelStates?.at(0);
    }

    useDataStore.setState({
      currentProductRelease: { ...productRelease, rules: productReleaseRules },
      variantFormState: VariantFormState.EDITING_NEW_VARIANT,
      selectedRepresentation: representationToSelect,
      mergedProductReleaseVariantInputsMap: inputsToUpdateMaps,
      configurableProductProperties: {
        inputs: [...inputsToUpdateMaps.values()],
        outputs: productRelease.outputs,
      },
      isFormInitializing: true,
    });
  },
  setIsFormInitializing: (isFormInitializing: boolean) => useDataStore.setState({ isFormInitializing }),
};
