import { ProductRelease, ReleaseStatus, Variant } from '@adsk/offsite-dc-sdk';
import { Dropdown } from '@mid-react-common/addins';
import { RightButton } from '@mid-react-common/common';
import { VariantFormState, VariantFormStates } from '@mid-react-common/revit-components';
import { useTheme } from '@mui/material/styles';
import { testIds } from 'global/dataTestIds';
import React from 'react';
import text from '../../global/mid-edit-form.text.json';
import useChangeRelease from './useChangeRelease';

interface ChangeReleaseProps {
  currentProductRelease: ProductRelease;
  nonObsoleteProductReleasesList: ProductRelease[];
  handleProductReleaseChange: (productRelease: ProductRelease) => void;
  variantFormState: VariantFormStates;
  setReFetchCachedVariants: (reFetchCachedVariants: boolean) => void;
  setSelectedCachedVariant: (selectedCachedVariant: Variant | null) => void;
}

const ChangeRelease: React.FC<ChangeReleaseProps> = ({
  currentProductRelease,
  nonObsoleteProductReleasesList,
  handleProductReleaseChange,
  variantFormState,
  setReFetchCachedVariants,
  setSelectedCachedVariant,
}) => {
  const theme = useTheme();
  const {
    showChangeReleaseButton,
    showChangeReleaseDropdown,
    productReleaseDropdownItems,
    selectedProductReleaseDropdownItem,
    handleChangeReleaseClick,
    handleChangeReleaseSelection,
  } = useChangeRelease({
    nonObsoleteProductReleasesList,
    selectedProductReleaseNumber: currentProductRelease.release,
    handleProductReleaseChange,
    setReFetchCachedVariants,
    setSelectedCachedVariant,
  });

  const isChangeReleaseButtonDisabled =
    variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
    // If the current product release is obsolete, we should enable the button if there is at least one product release
    (currentProductRelease.status === ReleaseStatus.OBSOLETE && nonObsoleteProductReleasesList.length === 0) ||
    // If the current product release is not obsolete, we should enable the button if there is two or more product releases
    (currentProductRelease.status !== ReleaseStatus.OBSOLETE && nonObsoleteProductReleasesList.length <= 1);

  return (
    <>
      {showChangeReleaseButton && (
        <RightButton
          variant="text"
          disabled={isChangeReleaseButtonDisabled}
          onClick={handleChangeReleaseClick}
          data-testid={testIds.midEditFormChangeReleaseButton}
        >
          {text.MIDEditForm.changeRelease}
        </RightButton>
      )}
      {showChangeReleaseDropdown && (
        <Dropdown
          elements={productReleaseDropdownItems}
          label={text.MIDEditForm.releasesDropdownLabel}
          selectedItem={selectedProductReleaseDropdownItem}
          onSelect={handleChangeReleaseSelection}
          width={`${theme.var.releaseDropdownWidth}px`}
          data-testid={testIds.midEditFormChangeReleaseDropdown}
          disabled={variantFormState === VariantFormState.GENERATING_NEW_VARIANT}
        />
      )}
    </>
  );
};

export default ChangeRelease;
